import { createApp } from 'vue'
import { createPinia } from 'pinia'
import "./base.css";
import App from './App.vue'
import router from './router'
import { createI18n } from "vue-i18n";

import en from "./locales/en.json";
import fr from "./locales/fr.json";
const i18n = createI18n({
  locale: "fr",
  messages: {
    fr,
    en,
  },
});

const pinia = createPinia()

createApp(App).use(i18n).use(pinia).use(router).mount('#app')
