<script>
import MenuMobileItem from './components/basic/MenuMobileItem.vue';
import sun from './assets/icons/theme-light.svg';
import moon from './assets/icons/theme.svg';
import { useUserStore } from './store/userStore';
export default {
  setup() {
    const userStore = useUserStore();
    if (localStorage.getItem("light-theme")) {
      document.body.classList.add("light-theme");
      userStore.isTheme = true;
    }
    return{sun, moon, userStore}
  },
  components:{
    MenuMobileItem
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || 'Itac';
    },
  },
  mounted(){
    document.title = this.$route.meta.title || 'Itac';
  },
  methods: {
    mounted() {
    if (localStorage.getItem("light-theme")) {
      this.userStore.isTheme = false;
      document.body.classList.add("light-theme");
    } else {
      this.userStore.isTheme = false;
      document.body.classList.remove("light-theme");
    }
  },
    logout() {
      localStorage.removeItem("authToken");
      this.$router.push("/signin");
    },
    changeLanguage() {
      this.$i18n.locale = this.$i18n.locale === "fr" ? "en" : "fr";
    },
    changeTheme() {
      const bodyElement = document.body;
      if (localStorage.getItem("light-theme")) {
        localStorage.removeItem('light-theme');
        bodyElement.classList.remove("light-theme");
        this.userStore.isTheme = false;
      } else {
        localStorage.setItem('light-theme',true);
        bodyElement.classList.toggle("light-theme");
        this.userStore.isTheme = true;
      }
    },
  },
};
</script>

<template>
  <nav class="app-nav-bar" v-if="!$route.meta.hideMenu">
    <router-link to="/" id="home-link">
      <img
        id="home-link-logo"
        alt="Weaverize Logo"
        src="./assets/icons/logo-itak.svg"
      />
      <div id="home-link-title"><img src="./assets/icons/logo-itac-text.svg" class="icon-invert" alt="itac"></div>
    </router-link>
    <transition name="fade-right" mode="out-in">
      <MenuMobileItem class="menu-mobile" />
    </transition>
    <div class="routes">
      <router-link class="nav-link" to="/argos" v-if="!$route.meta.hideNav">{{
        $t("main-menu.reco")
      }}</router-link>
      <router-link class="nav-link" to="/ulis" v-if="!$route.meta.hideNav">{{
        $t("main-menu.recup")
      }}</router-link>
      <router-link class="nav-link" to="/alcinos" v-if="!$route.meta.hideNav">{{
        $t("main-menu.event")
      }}</router-link>

      <img
        id="home-theme"
        alt="theme"
        class="icon-invert"
        :src="userStore.isTheme ? moon : sun"
        @click="changeTheme"
      />
      <img
        id="home-logout"
        alt="Weaverize Logo"
        class="icon-invert"
        src="./assets/icons/logout.svg"
        @click="logout"
      />
    </div>
  </nav>
  <router-view />
</template>

<style scoped>
#home-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: scale 0.2s ease;
}
#home-link-title {
  width:80px;
}
#home-link-logo {
  height: 45px;
  padding: 0 10px 0 30px;
  transition: scale 0.2s ease;
}
#home-link:hover {
  scale: 1.1;
}
#home-link-title {
  display: flex;
}
#home-logout {
  height: 21px;
  margin: 0 40px 0 12px;
  transition: scale 0.2s ease;
}
#home-logout:hover {
  cursor: pointer;
  scale: 1.1;
}
#home-theme {
  height: 28px;
  margin: 0 12px;
  transition: scale 0.2s ease;
}
#home-theme:hover {
  cursor: pointer;
  scale: 1.1;
}
.routes {
  display: flex;
  align-items: center;
}
.menu-mobile{
    display:none;
  }
.app-nav-bar {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-link {
  padding: 0 10px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  color: var(--white);
  transition: color 0.2s ease;
}
.nav-link:hover {
  color: var(--orange);
}
.nav-link.router-link-exact-active {
  color: var(--blue-dark);
  font-family: "LatoBold", sans-serif;
}
.fade-right-enter-active, .fade-right-leave-active {
  transition: all 0.2s ease-out;
}
.fade-right-enter-from,
.fade-right-leave-to {
  transform: scale(90%);
  opacity: 0;
}
@media screen and (max-width:1000px){
  .app-nav-bar{
    height: 60px;
  }
  #home-link-logo{
    height:30px;
    padding: 0 10px 0 10px;
  }
  #home-link-title{
    height:30px;
  }
  .routes{
    display: none;
  }
  .menu-mobile{
    display:block
  }
}
</style>
