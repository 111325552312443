import { defineStore } from "pinia";
import { callAPI } from "@/api/httpservice";

export const useUserStore = defineStore("user", {
  state: () => ({
    search: "",
    exampleItems: [
      { id: 1, name: "John Doe", mail: "john.doe@mail.com" },
      { id: 2, name: "Jane Smith", mail: "jane.smith@mail.com" },
      { id: 3, name: "Alice Johnson", mail: "alice.johnson@mail.com" },
      { id: 4, name: "Bob Brown", mail: "bob.brown@mail.com" },
      { id: 5, name: "Charlie Davis", mail: "charlie.davis@mail.com" },
      { id: 6, name: "David Wilson", mail: "david.wilson@mail.com" },
      { id: 7, name: "Eva Green", mail: "eva.green@mail.com" },
      { id: 8, name: "Frank White", mail: "frank.white@mail.com" },
      { id: 9, name: "Grace Miller", mail: "grace.miller@mail.com" },
      { id: 10, name: "Hank Martin", mail: "hank.martin@mail.com" },
    ],
    users: [],
    page: 1,
    itemPerPage: 10,
    loading: false,
    isTheme:false
  }),
  getters: {
    getTotal() {
      return Math.ceil(this.users.length / this.itemPerPage);
    },
    getPaginatedItems() {
      const start = (this.page - 1) * this.itemPerPage;
      const end = start + this.itemPerPage;
      return this.users.slice(start, end);
    },
  },
  actions: {
    async getAllUsers() {
      await callAPI("GET", `/users`, null).then((res) => {
        this.users = res;
      });
    },
    async getUser(id) {
      await callAPI("GET", `/users/${id}`, null).then((res) => {
        this.users = res;
      });
    },
    async deleteUser(id) {
      return await callAPI("DELETE", `/users/${id}`, { id }).then(() => {
        this.getAllUsers();
      });
    },
    async updateUser(user) {
      await callAPI("PATCH", `/users/${user.id}`, {
        email: user.email,
        username: user.username,
      }).then(() => {
        this.getAllUsers();
      });
    },
    async createUser(user) {
        await callAPI("POST", `/users`, {
          email: user.email,
          username: user.username,
          password:user.password
        }).then(() => {
          this.getAllUsers();
        });
      },
  },
});
