import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: { hideMenu: false, authPage: false, hideNav: true, title: 'Itac' },
  },
  {
    path: "/argos",
    name: "argos",
    component: () => import("../views/UploadView.vue"),
    meta: { hideMenu: false, authPage: false, title: 'Argos' },
  },
  {
    path: "/ulis",
    name: "ulis",
    component: () => import("../views/ListView.vue"),
    meta: { hideMenu: false, authPage: false, title: 'Ulis' },
  },
  {
    path: "/alcinos",
    name: "alcinos",
    component: () => import("../views/EventView.vue"),
    meta: { hideMenu: false, authPage: false, title: 'Alcinos' },
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("../views/Auth/SigninView.vue"),
    meta: { hideMenu: true, authPage: true, title: 'Itac' },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/Auth/ForgotPasswordView.vue"),
    meta: { hideMenu: true, authPage: true, title: 'Itac' },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/Auth/ResetPasswordView.vue"),
    meta: { hideMenu: true, authPage: true, title: 'Itac' },
  },
  {
    path: "/email-verification",
    name: "email-verification",
    component: () => import("../views/Auth/EmailVerificationView.vue"),
    meta: { hideMenu: true, authPage: true, title: 'Itac' },
  },
  {
    path: "/admin-user",
    name: "admin-user",
    component: () => import("../views/AdminView.vue"),
    meta: { hideMenu: true, authPage: false, title: 'Itac' },
  },
  
  {
    path: "/:pathMatch(.*)*",
    redirect: {name: 'home'}
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("authToken");

  if (isAuthenticated && to.meta.authPage) {
    next("/");
  } else if(!isAuthenticated && !to.meta.authPage){
    next("/signin");
  } else {
    next();
  }
  
});

export default router;
